@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.content-card-container {
    perspective: 800px;
    perspective-origin: 50% 50%;
}

.card-faces-container {
    transition: transform 1.25s ease-in-out;
    transform-style: preserve-3d;
    position: relative;
    margin: 0;

    &.rotated {
        transform: rotateY(-180deg);
    }

    &.no-animation {
        transition: none;
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.card-face {
    backface-visibility: hidden;
    position: relative;
    top: 0;
    left: 0;
}

.back-face {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(180deg);
}

@include media-breakpoint-up(sm) {
    .card-faces-container {
        margin: auto 0;
    }
}
