@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "variables";

.button-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    padding: 1px;

    .selectable-button {
        align-content: center;
        padding: map-get($spacers, 3);
        text-align: center;
        border-radius: $border-radius-sm;
        box-sizing: border-box;
        border: 1px solid transparent;
        transition: .2s ease-in;
        cursor: pointer;

        &:hover {
            border: 1px solid darken($well-background-color, 10%);
        }

        @for $i from 1 through length($interval-button-colors) {
            &.color-#{$i} {
                color: nth($interval-button-colors, $i);
            }
        }
    }

    &.small-grid .selectable-button {
        padding: map-get($spacers, 2);
    }

    input[type="checkbox"]:checked + .selectable-button,
    input[type="radio"]:checked + .selectable-button {
        background-color: $panel-background-color;
        border: 1px solid #fff;
        box-shadow: $box-shadow-sm;
        color: $blue-600;
        font-weight: 500;
    }
}

@include media-breakpoint-up(sm) {
    .button-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
