@import "bootstrap/scss/variables";
@import "variables";
@import "mixins";

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes pending {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.logo-header {
    position: relative;
    background-color: $logo-header-background-color;

    @include glassy-effect;

    &.failure::before,
    &.success::before,
    &.pending::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: fadeInAndOut 2s ease-in-out;
        opacity: 0;
    }

    &.success::before {
        background-image: radial-gradient(circle, $success-color, $logo-header-background-color);
    }

    &.failure::before {
        background-image: radial-gradient(circle, $failure-color, $logo-header-background-color);
    }

    &.pending::before {
        $highlight-color: #2196F3;
        background-image: radial-gradient(
                circle,
                lighten(rgba($highlight-color, 0.7), 20%) 0%,
                rgba($highlight-color, 0.5) 15%,
                $logo-header-background-color 100%
        );
        animation: pending 3s linear infinite;
    }
}

.logo {
    display: flex;
    justify-content: center;
    align-items: baseline;
    user-select: none;
    position: relative;
    padding: 0 map-get($spacers, 2);

    span {
        font-size: 5em;
        font-weight: 500;
        color: #121212;
        background-image: linear-gradient(to top, #040404, #121212);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
    }

    .bigger {
        font-size: 6em;
        font-weight: 600;
    }
}

.piano {
    background-image: url("../images/piano.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 110px;
    height: 110px;
    margin-right: map-get($spacers, 4);
}

@include media-breakpoint-up(md) {
    .logo {
        padding: 0;

        span {
            font-size: 6em;
        }

        .bigger {
            font-size: 7em;
        }
    }

    .piano {
        width: 150px;
        height: 150px;
    }
}
